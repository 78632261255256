/* globals $, ga, appConfig, pageData, MediaQuery, Modernizr, objectFitImages */

/* exported App */

'use strict';

var App = (function() {

    var config = {
            selectors: {
                window: window,
                body: 'body',


                header: '.js-header',
                navigation: '.js-navigation',
                siteLink: '.js-site-link',

                navigationToggle: '.js-navigation-toggle',

                projectListItem: '.js-project-list-item',

                projectSlideshow: '#js-project-slideshow',
                projectSlideshowTitle: '.js-project-slideshow-title',
                projectSlideshowPosition: '.js-project-slideshow-position',
                projectSlideshowCount: '.js-project-slideshow-count',
                projectSlideshowText: '.js-project-slideshow-text',

                projectSlideshowSectionTitle: '.js-project-slideshow-section-title',
                projectSlideshowSectionText: '.js-project-slideshow-section-text',

                projectTextToggle: '.js-project-text-toggle',

                directionHints: '.direction-hints',

                iFrameOverlays: '.iframe-overlay',

                inlineHoverImage: '.inline-hover-image',

                slideshow: '.js-slideshow',
            },

            smoothScrollingSpeed: 600,
            objectFitInterval: 3000,
            transitionDuration: 0, // CSS transition duration, read in later

            // Slideshow settings: optional(disabled) items are prefixed by a _

            slideshowSettings: {
                _autoplay: {
                    delay: 3000,
                    disableOnInteraction: false,
                },
                speed: 600,
                loop: true,
                direction: 'horizontal',
                grabCursor: true,
                mousewheelForceToAxis: true,
                navigation: {
                    prevEl: '.js-slideshow-prev',
                    nextEl: '.js-slideshow-next',
                    disabledClass: 'is-disabled',
                },
            },
        },

        data = {},

        selectors = {},

        elements = {}, $e,



    init = function(_config) {
        // Extend module config
        $.extend(true, config, _config || {});

        // Read config from meta tags
        readCSSConfig();

        // Cache all elements for later use
        cacheElements();

        // DOM and event bindings
        setupBindings();

        // Pub/sub
        setupEvents();

        // Object-fit images
        objectFitPolyfill();

        // Setup project slideshow
        setupProjectSlideshow();

        setupSlideshows();

        setupProjectList();

        setupInlineHoverImages();
    },



    cacheElements = function() {
        // Iterate over all selectors in config and store them as jQuery objects
        $.each(config.selectors, function(elementName, selector) {
            elements[elementName] = $(selector);
        });

        // Shortcuts
        selectors = config.selectors;
        $e = elements;
    },


    // DOM bindings
    setupBindings = function() {

        // Toggle mobile navigation
        $(document).on('click touchend', selectors.navigationToggle, function (event) {
            $e.body.toggleClass('is-navigation-visible');
        });

        // Resizing? Save to body
        $(window).on('resizestart',   0, function(){ $e.body.addClass('is-resizing'); });
        $(window).on('resizestop', 1000, function(){ $e.body.removeClass('is-resizing'); });

        // Breakpoint change
        $.subscribe('/mediaquery/change', function(_, size) {
            if (MediaQuery.atLeast('small')) {

            }
        });

        // Re-initialize page after ajax page loads
        $(window).on('statechangecomplete', onStateChange);

        // "Fake" state change to initialize everything now
        onStateChange();
    },

    // Observers for interaction with other modules
    setupEvents = function() {
        // Update CSS config on mediaquery change
        $.subscribe('/mediaquery/change', readCSSConfig);

        // Show and hide loading spinner when loading over Ajax
        $.subscribe('/ajax/start', function() { $e.body.addClass('is-loading'); });
        $.subscribe('/ajax/stop',  function() { $e.body.removeClass('is-loading'); });

        // Log all ajax errors to console
        $(document).ajaxError(function(event, jqXHR, settings, error) {
            console.error('Ajax request failed: ' + error + ' ('+settings.url+')');
        });
    },

    onStateChange = function() {
        openExternalLinksInNewTab();
    },


    pageHasTemplate = function(template) {
        return $e.body.hasClass('template-' + template);
    },

    readCSSConfig = function() {
        config.transitionDuration = getCSSConfigValue('transition') || 0;
    },

    getCSSConfigValue = function (key) {
        var configStr = $('.js-config-' + key).css('font-family');
        return configStr ? configStr.trim().slice(1, -1) : null; // browsers re-quote string style values
    },

    trackCustomEvent = function(category, action, label){
        ga('send', 'event', category, action, label);
    },

    objectFitPolyfill = function () {
        if (Modernizr.objectfit) {
            return;
        }

        if (typeof objectFitImages !== 'function') {
            return;
        }

        var triggerObjectFit = function () {
            objectFitImages(null, {watchMQ: true});
        };

        triggerObjectFit();
        setTimeout(triggerObjectFit, config.objectFitInterval / 2);
        setInterval(triggerObjectFit, config.objectFitInterval);
    },

    openExternalLinksInNewTab = function () {
        $('a')
            .filter('[href^="http"], [href^="//"]')
            .not('[href*="' + window.location.host + '"]')
            .attr('rel', 'noopener noreferrer')
            .attr('target', '_blank');
    },

    setupHoverImage = function($container) {
        var $link = $container.find('a, .a'),
            $image = $container.find('img'),
            $preview = $('<div class="hover-image"></div>');

        $image.css('max-width', ($image.attr('width') * 2/3) + 'px');
        $preview.append($image);
        $e.body.append($preview);

        $preview.toggleClass('landscape', $image.hasClass('landscape'));
        $preview.toggleClass('portrait', $image.hasClass('portrait'));
        $preview.toggleClass('square', $image.hasClass('square'));

        var setPreviewPosition = function (x, y) {
            $preview.css('transform', 'translate3d(' + x + 'px, ' + y + 'px, 0)');
        };

        $link.hover(function(event){
            var intent = $('html').data('whatintent'); // whatInput.ask('intent')
            if (intent && intent !== 'mouse') {
                return;
            }

            data.hidePreview = false;
            $(document).on('mousemove.imagepreview', function(event){
                setPreviewPosition(event.clientX, event.clientY);
            });
            setPreviewPosition(event.clientX, event.clientY);
            $preview.addClass('is-active');
        },
        function(){
            data.hidePreview = true;
            setTimeout(function(){
                if (data.hidePreview) {
                    $(document).off('mousemove.imagepreview');
                }
            }, 500);
            $preview.removeClass('is-active');
        });
    },

    setupInlineHoverImages = function () {
        $e.inlineHoverImage.each(function() {
            setupHoverImage($(this));
        });
    },

    setupProjectList = function () {
        $e.projectListItem.each(function() {
            setupHoverImage($(this));
        });
    },

    // Apply Fullpage plugin to project slideshow

    setupProjectSlideshow = function() {

        if (!$e.projectSlideshow.length) {
            return;
        }

        // Ungroup multi-panel slides on small devices

        var $sections = $e.projectSlideshow.find('.section'),
            slideTemplate = $('#slide-template').html(),
            isMultiPanel = MediaQuery.atLeast('medium'),
            skipSinglePanelTypes = ['color'];

        if (!isMultiPanel) {

            // Loop over sections

            $sections.each(function() {
                var $section = $(this),
                    $slides = $section.find('.slide');

                // Loop over slides

                $slides.each(function() {
                    var $slide = $(this),
                        $panels = $slide.find('.panel');

                    if ($panels.length > 1) {

                        $panels.slice(1).each(function() {
                            var $panel = $(this),
                                panelType = $panel.data('type'),
                                $newSlide = $(slideTemplate),
                                $newSlidePanel = $newSlide.find('.panel');

                            // Panels that should not stadn alone are removed
                            if (panelType && $.inArray(panelType, skipSinglePanelTypes) >= 0) {
                                $panel.remove();
                                return;
                            }

                            // Panel is cloned and put into its own slide
                            $newSlidePanel.html($panel.html());
                            $panel.remove();
                            $newSlide.insertAfter($slide);
                        });
                    }
                });

                // Re-assign ascending anchors
                var $slidesAfterChange = $section.find('.slide');
                $slidesAfterChange.each(function(index) {
                    $(this).get(0).setAttribute('data-anchor', index+1);
                });
            });
        }

        $e.projectSlideshow.toggleClass('is-multi-panel', isMultiPanel);
        $e.projectSlideshow.toggleClass('is-single-panel', !isMultiPanel);

        // Init fullpage.js

        var fixed = [
            selectors.header,
            selectors.navigation,
            selectors.siteLink,
        ].join(',');
        fixed = '';

        $e.projectSlideshow.fullpage({
            licenseKey: 'OPEN-SOURCE-GPLV3-LICENSE',
            continuousHorizontalKey: 'YnVlcm9uYXJkaW4uY29tX2ZuOVkyOXVkR2x1ZFc5MWMwaHZjbWw2YjI1MFlXdz1FZnI=',

            // Pull nav, upper corner titles and direction arrows out of scrolling behaviours
            fixedElements: fixed,

            // Ignore scrolling when over nav
            normalScrollElements: [
                selectors.navigation,
                selectors.projectSlideshowText,
            ].join(', '),

            continuousVertical: true,
            continuousHorizontal: true,
            verticalCentered: false,
            scrollingSpeed: 700,
            fitToSectionDelay: 150,
            easingcss3: 'cubic-bezier(.62,.1,.2,.9)',

            // lockAnchors: true,

            // Don't animate scrolling on first load, jump directly
            animateAnchor: false,

            recordHistory: false,

            lazyLoading: false,

            afterLoad: function(origin, destination, direction) {
                console.log('--- Fullpage: afterLoad');
                setProjectInfo(destination);
            },

            afterSlideLoad: function() {
                console.log('--- Fullpage: afterSlideLoad');
            },

            afterRender: function() {
                console.log('--- Fullpage: afterRender');
            },

            onLeave: function(origin, destination, direction) {
                console.log('--- Fullpage: onLeave');
                setProjectInfo(destination);
            }
        });

        var slideCount = $e.projectSlideshow.find('.fp-section').length;
        $e.projectSlideshowCount.html(slideCount);

        // Toggle project text display
        $e.projectTextToggle.click(function(){
            $e.body.toggleClass('is-project-text-visible');
        });
    },

    setProjectInfo = function (destination) {
        var $next = $(destination.item),
            title = $next.find(selectors.projectSlideshowSectionTitle).html(),
            text = $next.find(selectors.projectSlideshowSectionText).html();

        $e.projectSlideshowTitle.html(title || '');
        $e.projectSlideshowPosition.html(destination.index+1);
        $e.projectSlideshowText.html(text || '');

        $e.body.toggleClass('project-has-text', !!text);
    },

    getProjectSection = function (index) {
        return $e.projectSlideshow.find('.fp-section').eq(index);
    },

    getCurrentProjectSlide = function() {
        return $e.projectSlideshow.find('.fp-section.active .fp-slide.active').first();
    },


    setupSlideshows = function () {
        $e.slideshow.each(function(){
            var $slideshow = $(this),
                slideshowConfig = $.extend({}, config.slideshowSettings);

            if ($slideshow.is('[data-autoplay]')) {
                slideshowConfig.autoplay = slideshowConfig._autoplay;
                if ($slideshow.data('autoplay')) {
                    slideshowConfig.autoplay.delay = $slideshow.data('autoplay');
                }
            }
            if ($slideshow.is('[data-loop]')) {
                slideshowConfig.loop = true;
            }
            if ($slideshow.is('[data-speed]')) {
                slideshowConfig.speed = $slideshow.data('speed');
            }

            console.log(slideshowConfig);

            var swiperInstance = new Swiper(this, slideshowConfig);
        })
        .addClass('is-setup');
    };


    // Public properties and methods
    return {
        init: init,
    };

})();

// General site/app module

$(function() {

    // Initialize media query detection

    MediaQuery.init();

    // Load up app and initialize

    App.init();

});
